<template>
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <div
    class="modal fade"
    tabindex="-10"
    id="kt_modal_certificato_medico"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Accetta richiesta - {{ nominativo_completo }}
          </h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body">
          <div class="card-body">
            <div class="ps-5 pe-5 fw-bold row">
              <div class="col-md-12">
                <div class="row pb-4">
                  <div class="col-md-5">
                    <label class="fw-bold">Certificato medico</label>
                    <input
                      class="form-check-input mt-6"
                      type="checkbox"
                      name="flexcheckboxDefault"
                      :checked="certificato"
                      v-model="certificato"
                      @click="
                        data_certificato = '';
                        certificato = !certificato;
                      "
                      :disabled="onlyView"
                    />
                  </div>
                  <div class="col-md-6">
                    <label class="fw-bold">Data scadenza</label>
                    <Datepicker
                      v-model="data_certificato"
                      placeholder=""
                      :format="format"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      inputClassName="h-28"
                      autoApply
                      @update:model-value="
                        $event
                          ? (data_certificato = fromUTCtoISODate($event))
                          : ''
                      "
                      :disabled="!certificato"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeModalCertificato"
            @click="
              certificato = true;
              data_certificato = '';
            "
          >
            Chiudi
          </span>
          <span
            type="button"
            class="badge bg-secondary rounded blueFit fs-6"
            @click="accetta()"
          >
            Accetta
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { format, fromUTCtoISODate } from "@/composables/formatDate.js";

import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";

import { alertFailed } from "@/composables/swAlert";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "modal-certificato",
  data() {
    return {};
  },
  props: {
    nominativo_completo: {
      type: String,
      required: true,
    },
    id_richiesta: {
      type: Number,
      required: true,
    },
    id_societa: {
      type: Number,
      required: true,
    },
    extracomunitario: {
      type: Number,
    },
  },
  emits: ["getRichiesteUnaSocietaList", "accetta"],
  components: { Loading, Datepicker },
  setup(props, { emit }) {
    const data_certificato = ref(null);
    const certificato = ref(true);

    const accetta = () => {
      if (data_certificato.value) {
        emit(
          "accetta",
          props.id_richiesta,
          props.id_societa,
          certificato.value,
          data_certificato.value,
          props.extracomunitario
        );
      } else {
        alertFailed(
          "Per procedere è necessario inserire la data di scadenza del certificato medico"
        );
      }
    };

    return {
      format,
      fromUTCtoISODate,
      data_certificato,
      certificato,
      accetta,
    };
  },
});
</script>

<style scoped>
.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}

@media (max-width: 1200px) {
  .modal-dialog {
    max-width: 55%;
  }
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 65%;
  }
}

.form-check-input[type="radio"] {
  margin-top: 0.15rem !important;
  padding: 0px;
}
</style>
