<template>
  <div>
    <FiltriRichiesteSocieta
      @getRichiesteUnaSocietaList="searchRichiesteUnaSocList"
      @resetFilters="resetFilters"
    />
    <TableRichiesteSocieta
      @getRichiesteUnaSocietaList="getRichiesteUnaSocietaList"
      @resetFilters="resetFilters"
      :societa="societa"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import FiltriRichiesteSocieta from "@/components/components-fit/società/richieste/FiltriRichiesteSocieta.vue";
import TableRichiesteSocieta from "@/components/components-fit/società/richieste/TableRichiesteSocieta.vue";

export default defineComponent({
  name: "richiesteSocieta",
  components: {
    FiltriRichiesteSocieta,
    TableRichiesteSocieta,
  },
  props: {
    societa: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Richieste Societa", [
        "Lista Societa",
        "Dettaglio Societa",
      ]);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const stato = computed(() => store.getters.statoRichiesteUnaSoc);
    const stagione = computed(() => store.getters.stagioneSelected);
    const rowsToSkip = computed(() => store.getters.rowsToSkipRichiesteUnaSoc);
    const fetchRows = computed(() => store.getters.fetchRowsRichiesteUnaSoc);
    const sortColumn = computed(() => store.getters.sortColumnRichiesteUnaSoc);
    const sortOrder = computed(() => store.getters.sortOrderRichiesteUnaSoc);

    const getRichiesteUnaSocietaList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_societa: props.societa.id,
          stagione: stagione.value,
          stato: stato.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.SOCIETA_RICHIESTE_LIST,
        itemName: "societa_richieste_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersRichiesteUnaSoc");
      getRichiesteUnaSocietaList();
    };
    getRichiesteUnaSocietaList();
    const loaded = computed(() =>
      store.getters.getStateFromName("loadedsocieta_richieste_list")
    );
    const searchRichiesteUnaSocList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipRichiesteUnaSoc");
      getRichiesteUnaSocietaList();
    };

    return {
      getRichiesteUnaSocietaList,
      resetFilters,
      searchRichiesteUnaSocList,
    };
  },
});
</script>
