import services from "../axios/dbManag";

export const accettaRichiesta = (
  id_richiesta,
  id_societa,
  certificato,
  data_certificato
) => {
  return services.dbManag
    .post("/tesseramento/richieste/approva", {
      id_richiesta,
      id_societa,
      certificato,
      data_certificato,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const rifiutaRichiesta = (motivazione, id_Richiesta, id_Societa) => {
  return services.dbManag
    .post("/tesseramento/richieste/rifiuta", {
      motivazione,
      id_Richiesta,
      id_Societa,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
