<template>
  <div>
    <div class="card shadow">
      <div
        class="pt-4 pb-4 pe-4 ps-4"
        @keyup.enter="$emit('getRichiesteUnaSocietaList')"
      >
        <div class="row">
          <div class="col-sm-3">
            <SelectInput
              :options="statoRichiesteUnaSocLookup"
              name="statoRichiesteUnaSocLookup"
              placeholder="Stato"
              :value="statoRichiesteUnaSoc"
              @changeSelect="setStatoRichiesteUnaSoc($event)"
            />
          </div>
          <div class="text-end col-md-9">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('getRichiesteUnaSocietaList')"
              :disabled="!loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('resetFilters')"
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, watch } from "vue";
import { useStore } from "vuex";

import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "filtri-richieste-societa",
  emits: ["resetFilters", "getRichiesteUnaSocietaList"],
  props: {
    campiLocked: {
      type: String,
      required: true,
    },
  },
  components: {
    SelectInput,
  },
  setup(props, { emit }) {
    const store = useStore();

    const statoRichiesteUnaSoc = computed(
      () => store.getters.statoRichiesteUnaSoc
    );
    const setStatoRichiesteUnaSoc = (event) => {
      store.commit("setStatoRichiesteUnaSoc", event);
    };

    const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione, () => {
      emit("getRichiesteUnaSocietaList");
    });

    return {
      statoRichiesteUnaSoc,
      setStatoRichiesteUnaSoc,
      statoRichiesteUnaSocLookup: [
        { id: 4, label: "Nuova" },
        { id: 3, label: "Accettata" },
        { id: 2, label: "Rifiutata" },
      ],
      loaded: computed(() =>
        store.getters.getStateFromName("loadedsocieta_richieste_list")
      ),
    };
  },
});
</script>
