<template>
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <div class="modal fade" tabindex="-10" id="kt_modal_motivazione_rifiuto">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Rifiuta richiesta - {{ nominativo_completo }}
          </h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body">
          <div class="card-body">
            <div class="ps-5 pe-5 fw-bold row">
              <div class="col-md-12">
                <label class="fw-bold">Motivazione:</label>
                <textarea
                  type="text"
                  class="form-control"
                  placeholder=""
                  aria-label=""
                  v-model="motivazione"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeModalMotivazione"
          >
            Chiudi
          </span>
          <span
            type="button"
            class="badge bg-secondary rounded blueFit fs-6"
            @click="rifiuta()"
          >
            Rifiuta
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { format, fromUTCtoISODate } from "@/composables/formatDate.js";

import "vue3-date-time-picker/dist/main.css";

import { alertSuccess, alertFailed } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import { rifiutaRichiesta } from "@/requests/richiesteSocietaRequests";

export default defineComponent({
  name: "modal-motivazione",
  data() {
    return {};
  },
  props: {
    nominativo_completo: {
      type: String,
      required: true,
    },
    id_richiesta: {
      type: Number,
      required: true,
    },
    id_societa: {
      type: Number,
      required: true,
    },
  },
  emits: ["getRichiesteUnaSocietaList"],
  components: { Loading },
  setup(props, { emit }) {
    const motivazione = ref(null);

    const isLoading = ref(false);

    const rifiuta = async () => {
      if (motivazione.value != null) {
        isLoading.value = true;

        await rifiutaRichiesta(
          motivazione.value,
          props.id_richiesta,
          props.id_societa
        ).then((res) => {
          if (res.status == 200) {
            alertSuccess(
              "Richiesta rifiutata correttamente.",
              "kt_modal_motivazione_rifiuto"
            );
            emit("getRichiesteUnaSocietaList");
            motivazione.value = null;
          } else {
            alertFailed(
              res.data.message
                ? res.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
          }
          isLoading.value = false;
        });
      } else {
        const strMsg = ref("Motivazione obbligatoria");
        Swal.fire({
          html: strMsg.value,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }
    };

    return {
      format,
      fromUTCtoISODate,
      rifiutaRichiesta,
      rifiuta,
      isLoading,
      motivazione,
    };
  },
});
</script>

<style scoped>
.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}

@media (max-width: 1200px) {
  .modal-dialog {
    max-width: 55%;
  }
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 65%;
  }
}

.form-check-input[type="radio"] {
  margin-top: 0.15rem !important;
  padding: 0px;
}
</style>
