export function calcolaEta(dataDiNascita) {
  var oggi = new Date();
  var dataNascita = new Date(dataDiNascita);
  var eta = oggi.getFullYear() - dataNascita.getFullYear();

  // Verifica se il compleanno è già avvenuto quest'anno
  var meseCorrente = oggi.getMonth();
  var giornoCorrente = oggi.getDate();
  var meseNascita = dataNascita.getMonth();
  var giornoNascita = dataNascita.getDate();

  if (
    meseCorrente < meseNascita ||
    (meseCorrente === meseNascita && giornoCorrente < giornoNascita)
  ) {
    eta--;
  }

  return eta;
}
